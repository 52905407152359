import {
  // Autocomplete,
  Box,
  Button,
  // CircularProgress,
  debounce,
  // Divider,
  FormControl,
  // FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  // Switch,
  TextField,
  Typography,
} from '@mui/material';
import {useAppDispatch, useAppSelector} from '../../redux/store';
import {
  actualizarCliente,
  setInfoCliente,
} from '../../redux/slices/orden-slice';
import TitleText from '../title-text/title-text';
// import yup from '../../common/yup';
import DialogPopUp from '../dialog-pop-up/dialog-pop-up';
import React, {Fragment, useCallback, useEffect, useState} from 'react';
import {infoCliente, TipoPersona} from '../../services/dto/orden';
import {api, getCountryList} from '../../services';
import {LoadingButton} from '@mui/lab';
import yup from '../../common/yup';
import {startLoading, stopLoading} from '../../redux/slices/ui-slice';

import {entidadReceptoraSchema} from '../../redux/slices/orden-slice/orden.schemas';
import {putPostEntidadReceptora} from '../../redux/slices/orden-slice/orden.thunks';
import {EntidadReceptora} from '../../redux/slices/orden-slice/orden.types';
import EntidadReceptoraDetail from './entidad-receptora-detail';
import EntidadReceptoraSwitchForm from './entidad-receptora-switch-form';
import {msgSuccess} from '../../common/toast';

interface ApiResponse {
  ok: boolean;
  error?: string[];
  code: number;
  data?: EntidadReceptora;
}
interface TipoReceptor {
  id: number;
  name: string;
}

interface Pais {
  id: number;
  name: string;
}
function ClienteIfudisTab() {
  const dispatch = useAppDispatch();

  const [openCliente, setopenCliente] = useState(false);
  const cliente = useAppSelector(
    store => store.ordenSlice.orden.clienteSeleccionado,
  );
  const restaurante_id = useAppSelector(
    store => store.restauranteSlice.restauranteSeleccionado?.id_restaurante,
  );
  const [open, setOpen] = React.useState(false);

  const [loading, setLoading] = useState(false);

  const [options, setOptions] = useState<readonly infoCliente[]>([]);

  const [value, setValue] = React.useState<infoCliente | null>(null);

  const [inputValue, setInputValue] = React.useState('');

  const [nombre, setNombre] = useState('');

  const [numeroIdentificacion, setnumeroIdentificacion] = useState('');
  const [contacto, setContacto] = useState('');

  const [tipoContacto, setTipoContacto] = useState(0);
  const sucursal_id = useAppSelector(
    store => store.restauranteSlice.sucursalSeleccionada?.id_sucursal,
  );
  const [isLoading, setIsLoading] = useState(false);

  const [tiposPersona, setTiposPersona] = useState<TipoPersona[]>([]);

  const [checked, setChecked] = useState<boolean>(false);

  const [selectPersona, setSelectPersona] = useState(0);

  const [checkedAgregar, setCheckedAgregar] = useState(false);

  const [asignar, setAsignar] = useState<boolean>(true);

  const [nombreRz, setNombreRz] = useState('');
  const [ruc, setRuc] = useState('');
  const [email, setEmail] = useState('');
  const [dv, setDv] = useState('');
  const [error, setError] = useState<string | null>(null);

  const [paises, setPaises] = useState<Pais[]>([]);
  const [selectedPaisId, setSelectedPaisId] = useState<number>(0);

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await getCountryList();
        if (response.ok && response.data && Array.isArray(response.data)) {
          setPaises(response.data);
        } else {
          console.error(
            'La respuesta de getCountryList no contiene datos válidos:',
            response,
          );
        }
      } catch (error) {
        console.error('Error al obtener países:', error);
      }
    };
    fetchCountries();
  }, []);

  //ENTIDAD RECEPTORA
  const [entidadReceptoravisible, entidadReceptorasetVisible] = useState(false);
  const setEntidadReceptoraOnClienteRedux = (
    entidad_receptora: EntidadReceptora,
  ) => {
    var name_on_bill: any = '';
    var email_on_bill: any = '';
    if (
      entidad_receptora.tipo_receptor_id == 1 ||
      entidad_receptora.tipo_receptor_id == 3
    ) {
      name_on_bill = entidad_receptora.ruc_receptor?.name;
      email_on_bill = entidad_receptora.email;
    }
    if (
      entidad_receptora.tipo_receptor_id == 2 ||
      entidad_receptora.tipo_receptor_id == 4
    ) {
      name_on_bill = entidad_receptora.info_cliente_id?.nombre;
      email_on_bill = entidad_receptora.email;
    }
    dispatch(
      actualizarCliente({
        data: {
          name: name_on_bill,
          email: email_on_bill,
          entidad_receptora: entidad_receptora,
        },
        id: cliente?.id ?? '',
      }),
    );
    entidadReceptorasetVisible(!entidadReceptoravisible);
  };

  /// method to create or update entidad receptora object
  const addEntidadReceptora = async (
    data: yup.InferType<typeof entidadReceptoraSchema>,
  ) => {
    // by tipo_receptor_id delete info_cliente or ruc_receptor
    const tipo_receptor_id = data.tipo_receptor_id;
    var email_on_bill: any = null;
    if (tipo_receptor_id === 1 || tipo_receptor_id === 3) {
      delete data.info_cliente;
    }
    if (tipo_receptor_id === 2 || tipo_receptor_id === 4) {
      delete data.ruc_receptor;
    }
    if (data.email) {
      if (data.email != '') {
        email_on_bill = data.email;
      }
    }
    data.sucursal_id = sucursal_id as number;

    dispatch(startLoading());
    ////DEFINIENDO ESQUEMA PARA QUE FUNCIONE, PROBABLEMENTE TENGA QUE CAMBIAR EntidadReceptora
    await dispatch(putPostEntidadReceptora({...data, editId: data?.id}))
      .unwrap()
      .then(
        async (x: {
          data: {
            id: number;
            tipo_receptor_id: number;
            email: string;
            info_cliente: null;
          };
        }) => {
          /// Set entidad receptora on cliente redux
          setEntidadReceptoraOnClienteRedux({
            id: x.data.id,
            email: email_on_bill,
            tipo_receptor_id: x.data.tipo_receptor_id,
            info_cliente_id: data.info_cliente
              ? {
                  info_cliente_id: data.info_cliente.id,
                  num_id: data.info_cliente.info_cliente.num_id,
                  nombre: data.info_cliente.info_cliente.nombre,
                  pais_id: data.info_cliente.info_cliente.pais_id,
                  email: email_on_bill,
                }
              : undefined,
            ruc_receptor: data.ruc_receptor
              ? {
                  id: data.ruc_receptor.id,
                  tipo_persona_id: data.ruc_receptor.tipo_persona_id,
                  ruc: data.ruc_receptor.ruc,
                  name: data.ruc_receptor.name,
                  dv: data.ruc_receptor.dv,
                  email: email_on_bill,
                }
              : undefined,
          });
        },
      )
      .catch((e: any) => {
        console.log('han error as ocrrued creating or saving EntidadReceptora');
        console.log(e);
      });
    dispatch(stopLoading());
  };

  const changeEntidadReceptora = () => {
    entidadReceptorasetVisible(!entidadReceptoravisible);
  };

  const cancelEntidadReceptora = () => {
    entidadReceptorasetVisible(!entidadReceptoravisible);
  };

  //FIN ENTIDAD RECEPTORA

  const fetch = React.useMemo(
    () =>
      debounce(
        async (
          request: {input: string},
          callback: (results: readonly infoCliente[]) => void,
        ) => {
          setLoading(true);
          const results = await api.getInfoClientes(request.input);
          setLoading(false);
          if (results) callback(results.data);
        },
        200,
      ),
    [],
  );

  const closeModal = useCallback(() => {
    setopenCliente(false);
    setNombre('');
    setContacto('');
    setnumeroIdentificacion('');
    setSelectPersona(0);
    setRuc('');
    setDv('');
    setEmail('');
    setNombreRz('');
    setChecked(false);
    setCheckedAgregar(false);
    setAsignar(true);
    setSelectTipoReceptor(0);
  }, []);

  useEffect(() => {
    let active = true;
    if (inputValue === '') {
      //setOptions(value ? [value] : []);
      return undefined;
    }

    if (inputValue !== value?.num_id)
      fetch({input: inputValue}, (results: readonly infoCliente[]) => {
        if (active) {
          let newOptions: readonly infoCliente[] = [];

          if (value) {
            newOptions = [value];
          }

          if (results) {
            newOptions = [...newOptions, ...results];
          }

          setOptions(newOptions);
        }
      });

    return () => {
      active = false;
    };
  }, [value, inputValue]);

  useEffect(() => {
    let active = true;
    setNombre('');
    setContacto('');
    setnumeroIdentificacion('');
    loadTipoPersona().finally();

    /*fetch({input: ''}, (results: readonly infoCliente[]) => {
      if (active) {
        let newOptions: readonly infoCliente[] = [];

        if (results) {
          newOptions = [...newOptions, ...results];
        }

        setOptions(newOptions);
      }
    });*/
    return () => {
      active = false;
    };
  }, []);

  useEffect(() => {
    dispatch(setInfoCliente(value));
  }, [value]);

  const loadTipoPersona = useCallback(async () => {
    const response = await api.getTipoPersona();
    if (response) {
      setTiposPersona(response.data);
    }
  }, []);

  const [selectTipoReceptor, setSelectTipoReceptor] = useState<number>(0);

  // Cargar la lista de tipos de receptor desde la API
  const [tiposReceptor, setTiposReceptor] = useState<
    {id: number; name: string}[]
  >([]);

  const handleSubmmit = useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      setIsLoading(true);
      setError(null);

      let request: any = {
        sucursal_id: sucursal_id,

        tipo_receptor_id: selectTipoReceptor,
        info_cliente: {
          nombre: nombreRz,
          num_id: ruc,
          pais_id: selectedPaisId !== 0 ? selectedPaisId : '',
        },
        ruc_receptor: {
          tipo_persona_id: selectPersona,
          ruc: ruc,
          dv: dv,
          name: nombreRz,
        },
      };

      if (selectTipoReceptor === 4 || selectTipoReceptor === 2) {
        delete request.ruc_receptor;
      }
      if (selectTipoReceptor === 2) {
        delete request.info_cliente.pais_id;
      }

      if (selectTipoReceptor === 1 || selectTipoReceptor === 3) {
        delete request.info_cliente;
      }

      if (email) {
        request.email = email;
      }

      api
        .postEntidadReceptora(request)
        .then((response: any) => {
          if (response) {
            msgSuccess('Entidad receptora creada exitosamente');
            setopenCliente(false);
            closeModal();
          } else {
            setError(response.error[0]);
          }
        })
        .catch(response => {
          setError(response.error[0]);
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [email, sucursal_id, selectPersona, ruc, dv, nombreRz],
  );

  //SE ACTIVA CUANDO SE VAYAN A USAR TODOS LOS TIPOS DE RECEPTORES
  // useEffect(() => {
  //   const fetchTiposReceptor = async () => {
  //     try {
  //       const response = await api.getTipoReceptor();
  //       if (response && response.data && Array.isArray(response.data)) {
  //         setTiposReceptor(response.data);
  //       } else {
  //         console.error('Error al obtener los tipos de receptor:', response);
  //       }
  //     } catch (error) {
  //       console.error('Error al obtener los tipos de receptor:', error);
  //     }
  //   };

  //   fetchTiposReceptor();
  // }, []);

  useEffect(() => {
    const fetchTiposReceptor = async () => {
      try {
        const response = await api.getTipoReceptor();
        if (response && response.data && Array.isArray(response.data)) {
          const filteredTipos = response.data.filter(
            (tipo: TipoReceptor) =>
              tipo.id === 1 || tipo.id === 3 || tipo.id === 4 || tipo.id === 2,
          );
          setTiposReceptor(filteredTipos);
        } else {
          console.error('Error al obtener los tipos de receptor:', response);
        }
      } catch (error) {
        console.error('Error al obtener los tipos de receptor:', error);
      }
    };

    fetchTiposReceptor();
  }, []);

  // const array = [
  //   <>
  //     <TextField
  //       fullWidth
  //       value={clienteName || ''}
  //       onChange={e => dispatch(actualizarCliente({name: e.target.value}))}
  //       margin="none"
  //       label="NOMBRE"
  //       variant="outlined"
  //       InputProps={{style: {fontSize: '0.7rem'}}}
  //       InputLabelProps={{style: {fontSize: '0.7rem'}}}
  //     />
  //   </>,
  //   <>
  //     <UserSearch
  //       onSelected={value => {
  //         dispatch(actualizarCliente({ifudisUser: value || undefined}));
  //       }}
  //     />
  //   </>,
  // ];

  return (
    <>
      <Box sx={{mt: 1}}>
        {!entidadReceptoravisible && (
          <TitleText disableMb text="DATOS DEL CLIENTE" />
        )}
        {entidadReceptoravisible && (
          <TitleText disableMb text="DATOS DE LA ENTIDAD RECEPTORA" />
        )}
      </Box>
      {!entidadReceptoravisible && (
        <Box sx={{my: 2}}>
          <TextField
            fullWidth
            value={cliente?.name ?? ''}
            onChange={e => {
              dispatch(
                actualizarCliente({
                  data: {
                    name: e.target.value.toUpperCase(),
                    id_razon_social: value
                      ? value.tipo_personas && value.tipo_personas.length
                        ? value.tipo_personas[0].id_raz_soc
                        : undefined
                      : undefined,
                  },
                  id: cliente?.id ?? '',
                }),
              );
            }}
            margin="none"
            label="NOMBRE"
            variant="outlined"
            // InputLabelProps={{style: {fontSize: '0.8rem', color: 'black'}}}
            sx={{mb: '10px'}}
          />
          {
            <TextField
              fullWidth
              value={cliente?.email ?? ''}
              onChange={e => {
                dispatch(
                  actualizarCliente({
                    data: {email: e.target.value},
                    id: cliente?.id ?? '',
                  }),
                );
              }}
              margin="none"
              label="EMAIL"
              variant="outlined"
              // error={yup.string().email().isValidSync(cliente?.email) === false}
              // InputLabelProps={{style: {fontSize: '0.8rem', color: 'black'}}}
            />
          }
          {value && value.tipo_personas?.length ? (
            <Box paddingX={2} sx={{height: '80px', overflow: 'auto'}}>
              <Typography variant="subtitle1" gutterBottom>
                Razón social:
              </Typography>
              {value.tipo_personas.map((value, index) => (
                <Fragment key={index}>
                  <Grid container>
                    <Grid item xs={12}>
                      <Typography variant={'body2'}>
                        Nombre: {value.nombre}:
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant={'body2'}>
                        {value.tipo_persona}: {value.ruc}
                      </Typography>
                    </Grid>
                  </Grid>
                </Fragment>
              ))}
            </Box>
          ) : (
            ' '
          )}
          {cliente?.entidad_receptora && (
            <Grid container>
              <Grid item xs={12} md={12} xl={12} sm={12}>
                <Box sx={{mt: 1}}>
                  <TitleText disableMb text="ENTIDAD RECEPTORA SELECCIONADA" />
                </Box>
              </Grid>
              <Grid item xs={12} md={12} xl={12} sm={12}>
                <EntidadReceptoraDetail
                  entidad_receptora={cliente?.entidad_receptora}
                  changeEntidadReceptora={changeEntidadReceptora}
                />
              </Grid>
            </Grid>
          )}
        </Box>
      )}
      {entidadReceptoravisible && (
        <EntidadReceptoraSwitchForm
          cliente={cliente}
          handleFormSubmission={addEntidadReceptora}
          handleReduxUpdate={setEntidadReceptoraOnClienteRedux}
          handleCancelButton={cancelEntidadReceptora}
          tipo_receptor_id={selectTipoReceptor}
        />
      )}
      {
        <Box sx={{my: 2}}>
          <Grid container spacing={2} justifyContent={'end'}>
            {entidadReceptoravisible && (
              <Grid item>
                <Button
                  variant={'outlined'}
                  size="small"
                  onClick={() => setopenCliente(true)}
                  color="primary">
                  Crear cliente
                </Button>
              </Grid>
            )}
            {!entidadReceptoravisible && (
              <Grid item>
                <Button
                  variant="contained"
                  size="small"
                  onClick={() =>
                    entidadReceptorasetVisible(!entidadReceptoravisible)
                  }
                  color="secondary">
                  Factura con nombre
                </Button>
              </Grid>
            )}
            {entidadReceptoravisible && (
              <Grid item>
                <Button
                  variant="contained"
                  size="small"
                  onClick={() =>
                    entidadReceptorasetVisible(!entidadReceptoravisible)
                  }
                  color="primary">
                  Factura normal
                </Button>
              </Grid>
            )}
          </Grid>
        </Box>
      }
      {/*FORMULARIO DE CREAR UN CLIENTE*/}
      <DialogPopUp
        open={openCliente}
        handleClose={() => closeModal()}
        title={'Crear nuevo cliente'}>
        <Box marginY={2}>
          <form onSubmit={handleSubmmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Stack spacing={3}>
                  <Fragment>
                    <FormControl fullWidth>
                      <InputLabel id="demo-simple-select-label">
                        Tipo de receptor
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        label="Tipo de receptor"
                        value={selectTipoReceptor}
                        onChange={event =>
                          setSelectTipoReceptor(event.target.value as number)
                        }
                        required
                        id="demo-simple-select">
                        {tiposReceptor.map(tipo => (
                          <MenuItem key={tipo.id} value={tipo.id}>
                            {tipo.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {selectTipoReceptor !== 4 && selectTipoReceptor !== 2 && (
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          Tipo persona
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          label="Tipo persona"
                          value={selectPersona}
                          required
                          onChange={event =>
                            setSelectPersona(event.target.value as number)
                          }
                          id="demo-simple-select">
                          {tiposPersona.map((persona, index) => (
                            <MenuItem
                              key={index}
                              value={persona.id_tipo_persona}>
                              {persona.nombre}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                    {selectTipoReceptor === 4 && (
                      <FormControl fullWidth>
                        <InputLabel id="demo-simple-select-label">
                          País *
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          label="País"
                          value={selectedPaisId}
                          required
                          onChange={event =>
                            setSelectedPaisId(event.target.value as number)
                          }
                          id="demo-simple-select">
                          {paises.map(pais => (
                            <MenuItem key={pais.id} value={pais.id}>
                              {pais.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    )}
                    <TextField
                      fullWidth
                      value={nombreRz}
                      onChange={event =>
                        setNombreRz(event.target.value as string)
                      }
                      label={
                        tiposReceptor.some(tipo => tipo.id === 4)
                          ? 'Nombre'
                          : 'Nombre de razón social'
                      }
                      required
                    />
                    <TextField
                      fullWidth
                      value={ruc}
                      onChange={event => setRuc(event.target.value as string)}
                      label={
                        tiposReceptor.some(tipo => tipo.id === 4)
                          ? 'Número de Identificación'
                          : 'RUC o Cédula'
                      }
                      required
                    />
                    {selectTipoReceptor !== 4 && selectTipoReceptor !== 2 && (
                      <TextField
                        fullWidth
                        value={dv}
                        onChange={event => setDv(event.target.value as string)}
                        label="Dígito Verificador (DV)"
                      />
                    )}
                    <TextField
                      fullWidth
                      label="Email"
                      value={email}
                      onChange={event => setEmail(event.target.value as string)}
                    />
                  </Fragment>

                  <LoadingButton
                    size="large"
                    type="submit"
                    variant="contained"
                    color="secondary"
                    loading={isLoading}
                    disabled={
                      tiposReceptor.some(tipo => tipo.id === 4)
                        ? !(paises && nombreRz && ruc)
                        : !(dv && ruc && nombreRz && selectPersona)
                    }>
                    Crear
                  </LoadingButton>
                </Stack>
              </Grid>
            </Grid>
          </form>
        </Box>
      </DialogPopUp>
    </>
  );
}

export default ClienteIfudisTab;
