import {Button, Card, CardContent, Grid, Stack} from '@mui/material';
import ControlledSelector from '../form-input/controlled-selector';
import {ControlledTextField} from '../form-input/controlled-text-field';
import {entidadReceptoraSchema} from '../../redux/slices/orden-slice/orden.schemas';
import {
  EntidadReceptora,
  clienteStoreTypePrimary,
} from '../../redux/slices/orden-slice/orden.types';
import {getEntidadReceptora} from '../../redux/slices/orden-slice/orden.thunks';
import {LoadingButton} from '@mui/lab';
import yup from '../../common/yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {useAppDispatch} from '../../redux/store';
import {useEffect, useState} from 'react';
import {useForm} from 'react-hook-form';
import {getCountryList} from '../../services';

///  EntidadReceptora flat form
/// The user can create, search, select, and update.
type propsType = {
  cliente?: clienteStoreTypePrimary | null;
  handleFormSubmission?: any | null;
  handleReduxUpdate?: any | null;
  handleCancelButton?: any | null;
  incomingEntidadReceptora?: EntidadReceptora | null;
};

const EntidadReceptoraForm = function ({
  cliente,
  handleFormSubmission,
  handleReduxUpdate,
  handleCancelButton,
  incomingEntidadReceptora,
}: propsType) {
  const dispatch = useAppDispatch();
  //Form management with yup. Must define a "schema" formEntidadReceptoraSchema
  // defined on ../../redux/slices/orden-slice/orden.schemas
  const {control, handleSubmit, formState, getValues, setValue} = useForm<
    yup.InferType<typeof entidadReceptoraSchema>
  >({
    mode: 'onBlur',
    defaultValues: {
      tipo_receptor_id: 1,
      // info_cliente: {
      //   pais_id: 1, ///Default value Panama (Consumidor Final)
      // },
    },
    resolver: yupResolver(entidadReceptoraSchema),
  });
  // Submit button label values
  const PUP_UP_TITLE = 'Factura con nombre';
  const LABEL_SAVE = 'Agregar a factura ';
  const LABEL_UPDATE = 'Agregar a factura ';
  const LABEL_USE_SELECTED = 'Agregar a factura';
  const LABEL_CANCEL_BUTTON = 'Cancelar';
  // Component State
  const [formNatural, setFormNatural] = useState(true);
  const [requestPais, setRequestPais] = useState(false);
  const [suggestionsEnditadReceptora, setSuggestionsEnditadReceptora] =
    useState<EntidadReceptora[]>([]);
  const [tipoReceptorIdSelected, setTipoReceptorIdSelected] = useState('2');
  const [entidadReceptoraSelected, setEntidadReceptoraSelected] = useState<
    EntidadReceptora | undefined
  >(undefined);
  const [labelSubmitButton, setLabelSubmitButton] = useState(LABEL_SAVE);
  ///Load this data from endpoints
  const TIPO_RECEPTOR = [
    {id: 1, name: 'CONTRIBUYENTE'},
    {id: 2, name: 'CONSUMIDOR FINAL'},
    {id: 3, name: 'GOBIERNO'},
    {id: 4, name: 'EXTRANJERO'},
  ];
  const [paises, setPaises] = useState<
    {
      id: number;
      name: string;
      code: string;
      created_at: string;
      updated_at: string;
      deleted_at: string | null;
    }[]
  >([]); // Proporciona el tipo explícito para paises

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await getCountryList();

        // Verifica si la respuesta es exitosa y tiene datos
        if (response.ok && response.data && Array.isArray(response.data)) {
          // Actualiza el estado con los países
          setPaises(response.data);
        } else {
          // Si la respuesta no tiene datos válidos, imprime un mensaje de error
          console.error(
            'La respuesta de getCountryList no contiene datos válidos:',
            response,
          );
        }
      } catch (error) {
        console.error('Error al obtener países:', error);
      }
    };
    fetchCountries();
  }, []);

  const TIPO_PERSONAS = [
    {id_tipo_persona: 1, nombre: 'NATURAL'},
    {id_tipo_persona: 2, nombre: 'JURÍDICA'},
  ];
  /// Show fields by tipo_receptor_id. Rules defined on docs.
  // Implemented over tipo_receptor_id field

  /// Set form by tipo_receptor_id and num_id || ruc value
  // Its called on num_id input and ruc input keypress event
  const setFormType = (data: any) => {
    const type = data.id;
    setTipoReceptorIdSelected(data.id.toString());
    // dispatch(setTipoReceptorId(data.id.toString()));
    if (type == 1 || type == 3) {
      setFormNatural(false);
    }
    if (type == 2 || type == 4) {
      setFormNatural(true);
      if (type == 4) {
        setRequestPais(true);
      }
    }
  };
  ///Here.
  useEffect(() => {
    if (incomingEntidadReceptora) {
      const data: EntidadReceptora = incomingEntidadReceptora;
      ///expected behavior for updating
      if (data.id) {
        setEntidadReceptoraSelected(data);
        setValue('id', data.id);
        setValue('email', data.email);
      }
      /// Set form values
      setTipoReceptorIdSelected(
        (data.tipo_receptor_id ? data.tipo_receptor_id : 2).toString(),
      );
      setValue(
        'tipo_receptor_id',
        data.tipo_receptor_id ? data.tipo_receptor_id : 2,
      ); //By default is consumidor final
      ///aqui debo cambiar esta implementacion porque carmelo es un imbecil
      setValue(
        'info_cliente',
        data.info_cliente ? data.info_cliente : undefined,
      );
      setValue(
        'ruc_receptor',
        data.ruc_receptor ? data.ruc_receptor : undefined,
      );
      ///Set label button
      setLabelSubmitButton(LABEL_UPDATE);
      setFormType({id: data.tipo_receptor_id ? data.tipo_receptor_id : 2});
    }
  }, [incomingEntidadReceptora]);

  const fillFormByMatch = async (event: any) => {
    const tipo_receptor_id = parseInt(tipoReceptorIdSelected);
    const buscador = event.target.value;
    /// If the param is over 3 characters.
    if (buscador.length > 3) {
      await dispatch(getEntidadReceptora({tipo_receptor_id, buscador}))
        .unwrap()
        .then(async x => {
          setSuggestionsEnditadReceptora([...x]); ///Set list of EntidadReceptora state
        })
        .catch((e: any) => {
          console.log(e);
        });
    }
  };
  // Select EntidadReceptora from suggestionsEnditadReceptora state
  const selectEntidadReceptora = (data: EntidadReceptora) => {
    setEntidadReceptoraSelected(data);
    /// Set form values
    setValue('id', data.id);
    setValue(
      'tipo_receptor_id',
      data.tipo_receptor_id ? data.tipo_receptor_id : 2,
    ); //By default is consumidor final
    // setValue(
    //   'info_cliente',
    //   data.info_cliente_id ? data.info_cliente_id : undefined,
    // );
    setValue('ruc_receptor', data.ruc_receptor ? data.ruc_receptor : undefined);
    ///Set label button
    setLabelSubmitButton(LABEL_UPDATE);
    ///setSuggestionsEnditadReceptora([]);
  };
  // Submit form
  // Submit form
  const onSubmit = async (
    data: yup.InferType<typeof entidadReceptoraSchema>,
  ) => {
    // Verificar si el campo de correo está vacío
    if (data.email === '') {
      // Eliminar el campo de correo del objeto de datos para que no se envíe
      delete data.email;
      console.log('EMAIL:', data.email);
    }

    setEntidadReceptoraSelected(data);
    handleFormSubmission(data);
  };

  // Yup form errors
  const onInvalid = (errors: any) => console.error(errors);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={12}>
        <Card raised>
          <CardContent
            style={{
              textAlign: 'center',
            }}>
            <form onSubmit={handleSubmit(onSubmit, onInvalid)}>
              <Stack spacing={3}>
                {/* This selector have control over
                    form behavior */}
                <ControlledSelector
                  sourceData={TIPO_RECEPTOR}
                  controllerProps={{control, name: 'tipo_receptor_id'}}
                  title="Tipo de Receptor"
                  idKey="id"
                  valueKey={['name']}
                  fullWidth
                  handleOnChange={setFormType}
                  disabled
                />
                {/* filter match only by nombre o razons social field. Because expected behavior */}
                {!formNatural && (
                  <ControlledTextField
                    fullWidth
                    controllerProps={{control, name: 'ruc_receptor.name'}}
                    label={'Nombre o Razón social'}
                    onKeyUp={fillFormByMatch}
                    disabled
                  />
                )}
                {/* filter match only by numero ruc field. Because expected behavior */}
                {!formNatural && (
                  <Grid container spacing={1}>
                    <Grid item xs={10} md={10} xl={10} lg={10}>
                      {/* voy a poner la primera implementacion de esto aqui */}
                      <ControlledTextField
                        fullWidth
                        controllerProps={{control, name: 'ruc_receptor.ruc'}}
                        label={'RUC'}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={2} md={2} xl={2} lg={2}>
                      <ControlledTextField
                        fullWidth
                        controllerProps={{control, name: 'ruc_receptor.dv'}}
                        label={'DV'}
                        disabled
                      />
                    </Grid>
                  </Grid>
                )}
                {!formNatural && (
                  <ControlledSelector
                    sourceData={TIPO_PERSONAS}
                    controllerProps={{
                      control,
                      name: 'ruc_receptor.tipo_persona_id',
                    }}
                    title="Tipo de persona (RUC)"
                    idKey="id_tipo_persona"
                    valueKey={['nombre']}
                    fullWidth
                    disabled
                  />
                )}
                {/* filter match only by nombre field. Because expected behavior */}
                {formNatural && (
                  <ControlledTextField
                    fullWidth
                    controllerProps={{
                      control,
                      name: 'info_cliente.info_cliente.nombre',
                    }}
                    label={'Nombre'}
                    disabled
                  />
                )}
                {/* filter match only by numero de identificacion field. Because expected behavior */}
                {formNatural && (
                  <ControlledTextField
                    fullWidth
                    controllerProps={{
                      control,
                      name: 'info_cliente.info_cliente.num_id',
                    }}
                    label={'Número de identificación'}
                    disabled
                  />
                )}
                {formNatural === true && requestPais === true && (
                  <ControlledSelector
                    sourceData={paises}
                    controllerProps={{control, name: 'info_cliente.pais_id'}}
                    title="País"
                    idKey="id"
                    valueKey={['name']}
                    fullWidth
                    disabled
                  />
                )}

                <ControlledTextField
                  fullWidth
                  controllerProps={{control, name: 'email'}}
                  label={'Correo'}
                  onKeyUp={fillFormByMatch}
                />
              </Stack>
              <Grid
                item
                xs={12}
                spacing={2}
                style={{display: 'flex', justifyContent: 'end'}}
                sx={{my: 2}}>
                <Grid container>
                  <Grid item xs={6} lg={6} md={6} sm={6}>
                    {!entidadReceptoraSelected && (
                      <LoadingButton
                        size="small"
                        type="submit"
                        variant="contained"
                        color="primary"
                        loading={formState.isSubmitting}
                        disabled={!!Object.keys(formState.errors).length}>
                        {labelSubmitButton}
                      </LoadingButton>
                    )}
                    {entidadReceptoraSelected && (
                      <Button
                        size="small"
                        variant="contained"
                        color="primary"
                        onClick={() => {
                          //update redux with EntidadReceptora selected
                          handleFormSubmission(entidadReceptoraSelected);
                          handleReduxUpdate(entidadReceptoraSelected);
                        }}>
                        {LABEL_USE_SELECTED}
                      </Button>
                    )}
                  </Grid>
                  <Grid item xs={6} lg={6} md={6} sm={6}>
                    <Button
                      size="small"
                      color="secondary"
                      onClick={() => {
                        if (handleCancelButton) {
                          handleCancelButton();
                        }
                      }}>
                      {LABEL_CANCEL_BUTTON}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default EntidadReceptoraForm;
